<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Danh sách tài khoản</h6>
        </template>
        <b-row>
          <b-col class="col-md-4">
            <b-form-group label="Số điện thoại">
              <b-form-input
                v-model="filter.phone"
                placeholder="Số điện thoại"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-4">
            <b-form-group label="Họ tên/Email">
              <b-form-input
                v-model="filter.user_keyword"
                placeholder="Họ tên/Email"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-4">
            <b-form-group label="Địa chỉ/Số CCCD">
              <b-form-input
                v-model="filter.profile_keyword"
                placeholder="Địa chỉ/Số CCCD"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-4">
            <b-form-group label="Trạng thái">
              <b-form-select
                v-model="filter.status"
                :options="statusAccount"
                :text-field="'value'"
                :value-field="'key'"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col class="col-md-4">
            <b-form-group label="Lọc theo NFC">
              <b-form-select
                id="input-3"
                :text-field="'value'"
                :value-field="'key'"
                v-model="filter.profile_status"
                :options="optionNfc"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col class="col mb-2">
            <b-button class="mr-2" variant="outline-info" @click="search"
              >Tìm kiếm
            </b-button>
            <b-button class="mr-2" variant="outline-danger" @click="resetSearch"
              >Xóa bộ lọc
            </b-button>
            <b-button
              class="mr-2"
              variant="outline-success"
              @click="$bvModal.show(exportId)"
              >Xuất Excel
            </b-button>
          </b-col>
        </b-row>

        <hr />
        <div v-if="paginate.total > 0">
          <span>Tổng số: </span
          ><strong>{{ numberFormat(paginate.total) }}</strong>
        </div>
        <p v-if="!items">Không có dữ liệu</p>
        <b-table
          v-else
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(stt)="data">
            <div>
              {{ data.index + 1 }}
            </div>
          </template>
          <template #cell(type)="data">
            {{ parseInt(data.item.type) === 2 ? "Doanh nghiệp" : "Cá nhân" }}
          </template>
          <template #cell(status)="data">
            {{
              statusAccount.find(
                (item) => item.key === parseInt(data.item.status)
              )?.value ?? data.item.status
            }}
          </template>
          <template #cell(profile_status)="data">
            {{ parseInt(data.item.profile_status) ? "Có" : "Không quét" }}
          </template>
          <template #cell(options)="data" class="text-right">
            <a
              target="_blank"
              class="btn btn-outline-info"
              :href="'#/tools/users/account/view/' + data.item.id"
              >Chi tiết</a
            >
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
    <export-modal :modal-id="exportId" @exportData="exportData"></export-modal>
    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
  </div>
</template>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import router from "@/router";
import ExportModal from "@/view/pages/components/ExportModal.vue";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";

const CmsRepository = RepositoryFactory.get("cms");
const AccountRepository = RepositoryFactory.get("account");

Vue.use(Notifications);
export default {
  components: { AlertDialogue, ExportModal },
  mixins: [Common],
  data() {
    return {
      exportDesc: "",
      exportId: "export_lock_account",
      items: [],
      item: {
        name: null,
        time_frame: null,
        message: null,
        status: null,
        params: {},
      },
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      filter: {
        phone: null,
        user_keyword: null,
        profile_keyword: null,
        status: null,
        profile_status: null,
      },
      fields: [
        {
          key: "stt",
          label: "STT",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        { name: "Họ tên" },
        { email: "Email" },
        { phone: "Số điện thoại" },
        { type: "Loại" },
        { status: "Trạng thái" },
        { profile_status: "NFC" },
        { options: "Hành động" },
      ],
      statusAccount: [
        { key: null, value: "Chọn trạng thái" },
        { key: -3, value: "Xác thực thất bại" },
        { key: -2, value: "Đang chờ" },
        { key: 1, value: "Kích hoạt" },
        { key: 2, value: "Đã xác thực" },
        { key: -1, value: "Đã xác thực 1 bước" },
      ],
      optionNfc: [
        { key: "0", value: "Không quét" },
        { key: 1, value: "Có" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách hành động", route: "tools/users/block" },
    ]);
  },
  methods: {
    async exportData(description = null) {
      this.$bus.$emit("show-loading", true);
      let params = this.convert(this.filter);
      params.export_desc = description;
      this.$bvModal.hide(this.exportId);

      let response = await AccountRepository.exportDataList(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      this.$bus.$emit("show-loading", false);
      let link = window.location.origin + "/#/reconcile/export-data";
      let cfm = await this.$refs.confirmDialogue.show({
        title: "Download File",
        message:
          "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" +
          link +
          "' target='_blank'>TẠI ĐÂY</a>",
        okButton: "Ok",
      });

      if (!cfm) {
        self.$bus.$emit("show-loading", false);
        return (self.errorMessage = "Có lỗi khi lấy thông tin");
      }
    },
    convert(params) {
      for (let key in params) {
        if (params[key] == "" || params[key] == null) {
          delete params[key];
        }
      }
      return params;
    },
    search() {
      this.filter.page = 1;
      router
        .push({
          path: "/tools/users/account",
          query: this.convert(this.filter),
        })
        .catch(() => {});

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getList(this.currentPage);
    },
    resetSearch() {
      this.filter = {
        phone: null,
        user_keyword: null,
        profile_keyword: null,
        status: null,
        profile_status: null,
      };
      this.search();
    },
    getList(page = 1) {
      this.items = [];
      let params = { ...this.filter };
      params = this.convert(params);
      params.page = page;
      this.$bus.$emit("show-loading", true);
      CmsRepository.listAccount(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.items = response.data.data.data;
          this.paginate.total = response.data.data.total;
          this.paginate.totalPage = response.data.data.last_page;
          this.paginate.currentPage = response.data.data.current_page;
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
  },
  created() {
    this.query = this.$route.query;
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList(this.currentPage);
    },
  },
};
</script>
